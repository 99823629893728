import styled, { css } from 'styled-components';
import media from '../common/MediaQueries';
import { STEP_ICON_SIZE } from '../../constants/styleSetting';

// the whole container
const StepsContainer = styled.div`
  z-index: 99;
  margin: auto;
  width: 100vw;
  height: 100vh;
  max-width: 1000px;
  text-align: center;
  position: absolute;
  top: 0;
  left:0;
  right:0;
  bottom: 0;

  ${media.desktop`
    width: 95vw;
    height: 70vh;
  `}
`;

// select container for every step
const StepSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 100px 0 rgba(0,0,0,0.24);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: calc(100vh - 200px);
  max-width: 1000px;
`;

const StepWrap = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: ${STEP_ICON_SIZE / 2 + 30}px 0 30px 0;
  width: 100%;
  display: flex;
  max-height: calc(100vh - 230px);
  overflow: auto;
  ${props => props.noMaxHeight && css`
    max-height: none;
    overflow: visible;
  `}
  flex-direction: column;
  align-items: center;

  ${media.desktop`
    padding: ${STEP_ICON_SIZE / 2 + 10}px 20px 10px 20px;
  `}

  ${props => props.wide && css`
    padding: ${STEP_ICON_SIZE / 2 + 30}px 50px 30px 50px;
  `}
`;

// Icon container
const IconWrap = styled.div`
  position: absolute;
  top: -${STEP_ICON_SIZE / 2}px;
  width: 100%;
  z-index: 1;
`

export {
  StepsContainer,
  StepSelectContainer,
  StepWrap,
  IconWrap,
}

