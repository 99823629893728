import React from 'react';
import SearchableInput from 'react-searchable-input';
import styled, {css} from 'styled-components';
import {GREY_OUT_COLOR, PRIMARY_COLOR} from '../../constants/styleSetting';
import { ReactComponent as Search } from '../../img/search.svg';
import Loading from '../common/Loading';

import { useFetch } from '../../customHooks/fetch';

const defaultCollectionFormatter = (searchState) => searchState.data

const SearchWrap = styled.div`
  width: 200px;
  box-sizing: border-box;
  background: white;
  border-radius: 16px;
  border: 1px solid ${GREY_OUT_COLOR};
  padding: 8px 30px 8px 16px;
  position: relative;
  text-align: left;
  line-height: 1;
  ${props => props.valide && css`
    border-color: ${PRIMARY_COLOR};
  `}
  ul {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.24);
  }
`;

const fakeLoader = false;

function SearchBar({ maybeItemName, onSelectItem, renderListItem, collectionFormatter, searchFunc }) {
  const [searchState, doSearch] = useFetch(searchFunc)
   
  const handleSearch = (value) => {
    doSearch(value)
  }

  const handleSelect = (selectedArr) => {
    onSelectItem(selectedArr)
  }

  const collection = collectionFormatter ? collectionFormatter(searchState): defaultCollectionFormatter(searchState);

  return (
    <SearchWrap valide={!!maybeItemName}>
      <SearchableInput
        collection={collection}
        asyncSearch={handleSearch}
        placeholder={maybeItemName || 'search for an address'}
        showLabelText
        onListItemClick={handleSelect}
        theme={{
          inputHeight: '1em',
          listMaxHeight: "200px",
          listTop: '40px',
          listLeft: '-26px',
          listPadding: '20px',
          listWidth: '220px',
          listBg: 'white'
        }}
        renderListItem={renderListItem}
      />
      {searchState.isFetching || fakeLoader ? <Loading size='2px' style={{position: 'absolute', right: '8px', top: '9px', margin: '7px'}}/> : <Search style={{position: 'absolute', right: '8px', top: '9px'}}/>}
    </SearchWrap>
  )
}

export default React.memo(SearchBar)

