import React, {useMemo} from 'react';
import styled from 'styled-components';
import { StepWrap } from "./styledComponents";
import Flex from '../common/Flex';
import { H1, AdditionalInfo, Details } from '../common/Text';
import { GREY_OUT_COLOR, DEFAULT_FONT_SIZE } from '../../constants/styleSetting';
import { ReactComponent as TimeIcon } from "../../img/hours.svg";
import { TIME_RANGE } from '../../utils/getStepConfig';

const TimeInput = styled.div`
  border: 1px solid ${GREY_OUT_COLOR};
  border-radius: 16px;
  margin: 0.5em 1em;
  padding: 0.2em 0.5em;
  display: flex;
  align-items: center;
`

const TimeSelect = styled.select`
  border:none;
  appearance: none;
  margin: 0.2em 0.5em;
  font-size: ${DEFAULT_FONT_SIZE};
`
const format = num => num < 10 ? `0${num}` : num.toString();
const hoursArray = Array.from(Array(24).keys()).map(format)
const minutesArray = Array.from(Array(60).keys()).filter(minute => minute % 15 === 0).map(format)

const calculateMinute = ({ startHour, endHour, startMinute }) => {
  return TIME_RANGE - (Number(endHour) * 60 - Number(startHour) * 60 - Number(startMinute))
}

function TimePeroid({timeWindow, setTimeWindow}) {
  const {startHour, startMinute, endHour, endMinute} = timeWindow;
  const onInputChange = (field) => (e) => {
    setTimeWindow({
      ...timeWindow,
      [field]: e.target.value
    })
  }
  const disabledEndMinute = useMemo(() => calculateMinute({ startHour, endHour, startMinute }), [startHour, endHour, startMinute])
  return (
    <StepWrap>
      <H1>When do you want to go? </H1>
      <AdditionalInfo>Tell us when you are available so that we tailor our recommendations accordingly</AdditionalInfo>
      <Details>Please select a time frame of at least 2 hours to get a sufficient number of itineraries</Details>
      <Flex style={{margin: '20px auto'}}>
        <span>From</span>
        <TimeInput>
          <TimeSelect value={startHour} onChange={onInputChange('startHour')}>
            {hoursArray.map(hour => <option key={`start-${hour}`} value={hour}>{hour}</option>)}
          </TimeSelect>
          <span> : </span>
          <TimeSelect value={startMinute} onChange={onInputChange('startMinute')}>
            {minutesArray.map(minute => <option key={`start-${minute}`} value={minute}>{minute}</option>)}
          </TimeSelect>
          <TimeIcon style={{marginLeft: '5px'}}/>
        </TimeInput>
        <span>To</span>
        <TimeInput>
          <TimeSelect value={endHour} onChange={onInputChange('endHour')}>
            {hoursArray.map(hour => <option key={`end-${hour}`} disabled={Number(hour) < Number(startHour) + 2} value={hour}>{hour}</option>)}
          </TimeSelect>
          <span> : </span>
          <TimeSelect value={endMinute} onChange={onInputChange('endMinute')}>
            {minutesArray.map(minute => <option key={`end-${minute}`} disabled={Number(minute) < disabledEndMinute} value={minute}>{minute}</option>)}
          </TimeSelect>
          <TimeIcon style={{marginLeft: '5px'}}/>
        </TimeInput>
      </Flex>
    </StepWrap>
  )
}

export default TimePeroid