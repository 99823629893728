import React, { useMemo, useCallback } from "react";
import { ReactComponent as Filter } from '../../img/filter.svg';
import styled from 'styled-components';
import { addOrRemoveItem } from '../../utils/stepsHelper';
import { HEADER_VERTICAL_PADDING, PRIMARY_COLOR, DEFAULT_COLOR } from '../../constants/styleSetting';
import { H2, AdditionalInfo } from '../common/Text';
import media from '../common/MediaQueries';
import Button from '../common/Button';
import SelectCheck from '../common/SelectCheck';
import { getProps } from '../../utils/mapHelper';
import categoryMap from '../../constants/categoryMap';

const PanelContainer = styled.div`
  width: 20vw;
  height: 100vh;
  z-index: 4;
  box-sizing: border-box;
  padding: 0 20px;
  box-shadow: 0 0 18px 0 rgba(0,0,0,0.24);
  display:flex;
  flex-direction: column;
`;

const PanelHeader = styled.div`
  height: 73px;
  box-sizing: border-box;
  border-bottom: 1px dotted ${PRIMARY_COLOR};
  padding: ${HEADER_VERTICAL_PADDING} 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h2 {
    width: calc(100% - 112px);
    text-align: center;
  }
`;

const PanelContent = styled.div`
  flex: 1;
`

const CatTitle = styled(AdditionalInfo)`
  font-weight: normal;
  text-transform: uppercase;
  padding: 5px 0;
`

const PanelFooter = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`

const HeaderIcon = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FilterButton = styled(Button)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 20px 10px 0;
  padding: 16px;
  ${media.desktop`
    padding: 10px;
  `}
`

const addOrIgnore = (arr, item) => arr.includes(item)? arr : [...arr, item];

const calcFilters = (singleZonePoiFeatures) => singleZonePoiFeatures.reduce((acc, feat) => {
  const {cat, subcat} = getProps(feat);
  return {
    ...acc,
    [cat]: acc[cat] ? addOrIgnore(acc[cat], subcat) : [subcat]
  }
}, {})


function Panel({ singleZonePoiFeatures, filtered, changeFilters, updateSelectedZone }) {

 const filters = useMemo(() => calcFilters(singleZonePoiFeatures), [singleZonePoiFeatures])

 const onFilterClick = (item) => () => changeFilters(addOrRemoveItem(filtered, item));

 const onCloseClick = useCallback(() => {
    changeFilters([]);
    updateSelectedZone(undefined);
 }, [changeFilters, updateSelectedZone])

  return (
   <PanelContainer>
     <PanelHeader>
      <HeaderIcon><Filter /></HeaderIcon>
      <H2 bold>Explore around</H2>
     </PanelHeader>
     <PanelContent>
       {Object.entries(filters).map(([cat, subCat]) => {
         return (
           <div key={`${cat}-${subCat}`}>
             <CatTitle>{cat}</CatTitle>
             {subCat.map(sc => {
              const { label, Icon } = categoryMap[sc];
              const unSelected = filtered.includes(sc);
              return <FilterButton
                key={label}
                style={{position: 'relative', color: PRIMARY_COLOR}}
                unSelected={unSelected}
                onClick={onFilterClick(sc)}
              >
                <SelectCheck style={{flex: "0 0 16px"}} checked={!unSelected} />
                <Icon style={{width: "30px", height: "30px", margin: "0 10px"}}/>
                <span style={{color: unSelected ? DEFAULT_COLOR : PRIMARY_COLOR}}>{label}</span>
              </FilterButton>
             })}
          </div>
          )
       })}
     </PanelContent>
     <PanelFooter>
      <Button navigation primary onClick={onCloseClick}>
        <span style={{ marginLeft: "5px" }}>Leave neighbourhood</span>
      </Button>
    </PanelFooter>
   </PanelContainer>
  );
}

export default React.memo(Panel);
