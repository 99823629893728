import {useState, useReducer, useEffect } from 'react';

const initialState = {
  isFetching: false,
  hasError: false,
  result: undefined,
}

function fetchReducer(state, action) {
  switch (action.type) {
    case 'GET_DATA':{
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: null,
        result: undefined
      }
    }
    case 'SET_DATA': {
      return {
        ...state,
        isFetching: false,
        result: action.payload
      }
    }
    case 'SET_ERROR':
      return {
        isFetching: false,
        hasError: true,
        error: action.payload,
        result: undefined,
      }
    case 'RESET': 
      return initialState
    default:
      return state
  }
}

export function useFetch(fetchFunc) {
  const [fecthInfo, setFecthInfo] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  useEffect(() => {
    if (shouldReset) {
      dispatch({ type: 'RESET' });
    }
  }, [shouldReset]);
  const [fetchState, dispatch] = useReducer(fetchReducer, initialState);
  useEffect(() => {
    const fetchData = async (maybeInfo) => {
      dispatch({ type: 'GET_DATA' });
      try {
        // note here the maybeInfo can be a post body, or a boolean
        const result = await fetchFunc(maybeInfo);
        dispatch({ type: 'SET_DATA', payload: result });
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error });
      }
    };
    if (fecthInfo) {
      fetchData(fecthInfo);
    }
  }, [fecthInfo, fetchFunc]);

  return [fetchState, setFecthInfo, setShouldReset]
}