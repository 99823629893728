import React from 'react';
import styled from 'styled-components';
import { FooterText } from './common/Text';
import { ReactComponent as MasterCard } from '../img/mastercard.svg';

const FooterContainer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
`

function Footer() {
  return (
    <FooterContainer>
      <FooterText>You are a beta tester of ParisForYou, an innovative solution for visiting Paris.</FooterText>
      <FooterText>In collaboration with</FooterText>
      <MasterCard style={{margin: '0 10px'}}/>
      <FooterText>we suggest itineraries for you already tested by American tourists. </FooterText>
    </FooterContainer>
  );
}

export default Footer;
