import React, { useMemo, useContext } from "react";
import { MapContext } from '../map/mapContext';
import ZoneArea from "./ZoneArea";
import ZoneMainPath from './ZoneMainPath';
import ZoneDetails from './ZoneDetails';
import { getCoords } from "../../utils/mapHelper";
import ZonePoint from "./ZonePoint";

function MapZone({
  zone,
  journey,
  path_directions,
  zoneIndex,
  selectedZone,
}) {
  const { noZoneSelected } = useContext(MapContext)
  const mainPOI = useMemo(
    () => (journey && journey.features ? journey.features.find(feat => feat.geometry.type === "Point") : null),
    [journey]
  );
  const mainPOICoords = useMemo(() => getCoords(mainPOI), [mainPOI]);
  const zoneCenter = useMemo(() => getCoords(path_directions)[0], [
    path_directions
  ]);
  const zoneSelected = useMemo(() => selectedZone === zoneIndex, [selectedZone, zoneIndex])
  const shouldShowMainPath = useMemo(() => noZoneSelected || zoneSelected, [noZoneSelected, zoneSelected])

  return (
    <>
      <ZoneArea
        zone={zone}
        zoneCenter={zoneCenter}
        zoneIndex={zoneIndex}
        zoneSelected={zoneSelected}
      />
      {shouldShowMainPath &&
        <ZoneMainPath path={path_directions} zoneCenter={zoneCenter} />
      }
      {!zoneSelected && mainPOICoords && (
        <ZonePoint
          key={`${mainPOICoords[0]}-${mainPOICoords[1]}`}
          feat={mainPOI}
          popUpOffset={[0, -35]}
          icon="poi"
          size={0.6}
          isMain
        />
      )}
      {zoneSelected && <ZoneDetails journey={journey} />}
    </>
  );
}

export default React.memo(MapZone);
