import { ReactComponent as Nature } from "../img/nature.svg";
import { ReactComponent as Museum } from "../img/museum.svg";
import { ReactComponent as Urban } from "../img/urban.svg";
import { ReactComponent as Bakery } from "../img/bakery.svg";
import { ReactComponent as Wine } from "../img/wine.svg";
import { ReactComponent as Chocolate } from "../img/chocolate.svg";
import { ReactComponent as Delicate } from "../img/delicate.svg";
import { ReactComponent as Art } from "../img/art.svg";
import { ReactComponent as Library } from "../img/library.svg";
import { ReactComponent as Antique } from "../img/antique.svg";
import { ReactComponent as Cinemas } from "../img/cinemas.svg";
import { ReactComponent as Food } from "../img/food.svg";
import { ReactComponent as Flea } from "../img/flea.svg";
import { ReactComponent as Auction } from "../img/auction.svg";

const categoryMap = {
  'Nature & Parks': {
    label: 'Nature & Parks',
    Icon: Nature,
  },
  'Museums': {
    label: 'Museums',
    Icon: Museum
  },
  'Sights & Landmarks': {
    label: 'Sights & Landmarks',
    Icon: Urban
  },
  'Bakeries & Patisseries': {
    label: 'Bakeries & Patisseries',
    Icon: Bakery
  },
  'Wine cellars': {
    label: 'Wine cellars',
    Icon: Wine
  },
  'Chocolate factories': {
    label: 'Chocolate factories',
    Icon: Chocolate
  },
  'Fine food stores': {
    label:'Fine food stores',
    Icon: Delicate
  },
  'Bookshops': {
    label: 'Bookshops',
    Icon: Library
  },
  'Antique shops': {
    label:'Antique shops',
    Icon: Antique
  },
  'Art galleries': {
    label:'Art galleries',
    Icon: Art
  },
  'Cinemas': {
    label:'Cinemas',
    Icon: Cinemas
  },
  'Food markets': {
    label: "Food markets",
    Icon: Food
  },
  'Flea markets': {
    label: 'Flea markets',
    Icon: Flea
  },
  'Auction houses': {
    label: 'Auction houses',
    Icon: Auction
  }
}

export default categoryMap;