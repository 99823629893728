import React, {useCallback, useMemo, useContext} from 'react';
import styled from 'styled-components';
import { Popup } from "react-mapbox-gl";
import { MapContext } from '../map/mapContext';
import PolygonLayer from "./layers/PolygonLayer";
import { getCoords, getProps } from "../../utils/mapHelper";
import {MAP_LAYERS_COLOR,} from "../../constants/styleSetting";
import { StyledPopup, PopUpText } from './styledComponents';

const ZonePopUpTitle = styled(PopUpText)`
  font-size: 20px;
  text-align: left;
  font-weight: 700;
  border-radius: 8px;
  background-color: ${props => props.color};
  color: white;
`;

const ZonePopupDetail = styled(PopUpText)`
  font-size: 14px;
  text-align: left;
  color: ${props => props.color};
`

function ZoneArea({zone, zoneIndex, zoneCenter, zoneSelected}){
  const { updateSelectedZone } = useContext(MapContext);
  const color = useMemo(() => MAP_LAYERS_COLOR[zoneIndex], [zoneIndex]);
  const {coords, props} = useMemo(() => ({
    coords: getCoords(zone),
    props: getProps(zone)
  }), [zone]);
  const onPolygonClick = useCallback(() => {
    if (updateSelectedZone) {
      updateSelectedZone(zoneIndex)
    }
  },[updateSelectedZone, zoneIndex])
  return (
    // coords is a 2 dimensional array
    coords ? (
      <>
        {props && (
          <Popup style={{zIndex: 10}} offset={[0, 0]} coordinates={zoneSelected ? coords[0][0] : zoneCenter}>
            <StyledPopup main>
              <ZonePopUpTitle color={color}>{`${props.districtName.split(',').slice(0,2).join(', ')}`}</ZonePopUpTitle>
              {props.hashtags && <ZonePopupDetail color={color}>{props.hashtags.reduce((acc, tag) => `${acc} #${tag}`, '')}</ZonePopupDetail>}
            </StyledPopup>
          </Popup>
        )}
        <PolygonLayer 
          color={color}
          coordinates={coords}
          onPolygonClick={onPolygonClick}
          zoneSelected={zoneSelected}
        />
      </>
    ) : null
  );
};

export default ZoneArea