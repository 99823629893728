import React, { useMemo, useCallback } from "react";
import ReactMapboxGl, { ZoomControl, Image } from "react-mapbox-gl";
import memoize from "lodash.memoize";
import isEmpty from 'lodash/isEmpty';
import IconLayer from "../mapView/layers/IconLayer";
import MapZone from "../mapView/MapZone";
import { getCoords, getProps } from "../../utils/mapHelper";

import {
  ACCESS_TOKEN,
  MAP_STYLE,
  LARGE_PARIS_BOUNDARIES,
  PARIS_CENTER,
  MIN_ZOOM,
  MAX_ZOOM
} from "../../constants/mapSetting";

const createMap = accessToken =>
  ReactMapboxGl({
    accessToken,
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
    renderWorldCopies: false,
    failIfMajorPerformanceCaveat: false,
    dragRotate: false,
    touchZoomRotate: false
  });

const memoizedMap = memoize(createMap);

// the last coordinates pair inside path_directions for every zone is the starting point
// normally it's the same as the user input in 1st step
// so we can just take the first zone's path_directions, and get the last pair as the center
const userStartPoint = (geoJson) => !isEmpty(geoJson) ? getCoords(geoJson[0]["path_directions"]).slice(-1)[0] : PARIS_CENTER;

function EnhancedMap({geoJson, selectedZone, setMapInstance }) {
  const access_token = process.env.REACT_APP_MAPBOX_TOKEN || ACCESS_TOKEN;
  const Map = memoizedMap(access_token);
  
  const startPoint = useMemo(() => userStartPoint(geoJson), [geoJson])

  const _onStyleLoad = useCallback((map, e) => {
    setMapInstance(map);
  },[setMapInstance]);

  const _onZoom = useCallback((map,e) => {
    map.resize()
  }, [])

  const _containerStyle = {
    marginTop: '73px',
    height: "calc(100vh - 73px)",
    width: selectedZone !== undefined ? '80vw': '100vw',
    position: "relative"
  };

  return (
    <Map
      key="map"
      style={MAP_STYLE}
      containerStyle={_containerStyle}
      maxBounds={LARGE_PARIS_BOUNDARIES}
      onStyleLoad={_onStyleLoad}
      onZoom={_onZoom}
    >
      {geoJson &&
        geoJson.map((zone, index) => (
          <MapZone
            key={getProps(zone.zone).districtName}
            {...zone}
            zoneIndex={index}
            selectedZone={selectedZone}
          />
        ))}
      {geoJson && (
        <IconLayer
          icon="startPoint"
          size={0.7}
          anchor="center"
          coordinates={startPoint}
        />
      )}
      <Image id={"poi"} url={"/poi.png"} />
      <Image id={"poiBlack"} url={"/poiBlack.png"} />
      <Image id={"point"} url={"/point.png"} />
      <Image id={"startPoint"} url={"/startPoint.png"} />
      <Image id={"arrive"} url={"/arrive.png"} />
      {geoJson && <ZoomControl />}
    </Map>
  );
}

export default React.memo(EnhancedMap);
