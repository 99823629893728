import React from 'react';
import styled from 'styled-components';
import { STEP_ICON_SIZE, STEP_ICON_COLOR } from '../../constants/styleSetting';

const SVGWrap = styled.div`
  margin: 0 auto;
  width: ${STEP_ICON_SIZE}px;
  height: ${STEP_ICON_SIZE}px;
  border-radius: 50%;
  background-color: ${STEP_ICON_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
`

function StepIcon({ icon: Icon }) {
  return (
    <SVGWrap><Icon /></SVGWrap>
  )
}

export default StepIcon