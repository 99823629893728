import React from 'react';
import styled, {css} from 'styled-components';
import Button from '../common/Button';
import media from '../common/MediaQueries';
import { GREY_OUT_COLOR, PRIMARY_COLOR } from '../../constants/styleSetting';

const StepCounterWrap = styled.div`
  box-sizing: border-box;
  padding: 30px 10% 10px;
  width: 100%;
  max-width: 650px;
  margin: auto;

  ${media.desktop`
    padding: 10px 10%;
  `}

  ${props => props.wide && css`
    padding: 30px 15% 10px;
  `}
`;

const NavButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

const Dot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 4px;
  border-radius: 50%;
  background-color: ${GREY_OUT_COLOR};
  transition: background-color 0.3s ease;

  ${props => props.current && css`
      background-color: ${PRIMARY_COLOR};
    `};
`

function StepCounter({ total, currentStep, prev, next, onBack, onNext, disabled, sendInfo }) {
  const dots = [...Array(total).keys()];
  const onNextClick = currentStep === total - 1 ? sendInfo : onNext;
  return (
    <StepCounterWrap>
      <NavButtonContainer>
        {prev && <Button navigation greyOut onClick={onBack}>{prev}</Button>}
        {next && <Button style={{flex: 1}} navigation primary disabled={disabled} onClick={onNextClick}>{next}</Button>}
      </NavButtonContainer>
      <div>
        {
          dots.map(i => (
            <Dot key={i} current={i === currentStep }/>
          ))
        }
      </div>
    </StepCounterWrap>
  )
}

export default StepCounter