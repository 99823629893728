import React from 'react';
import { prop } from './lens';
import { SubText } from "../components/common/Text";

const renderSearchListItem = item => {
  // ref to the searchCollectionFormatter below & returned geojson data
  const [address, ...rest] = item.label.split(",");
  return (
    <div key={item.id} style={{ backgroundColor: "white", padding: "5px" }}>
      <span>{address}</span>
      <SubText>{rest.join()}</SubText>
    </div>
  );
};

const searchCollectionFormatter = searchState => {
  const features = prop(['result', 'data', 'features'], searchState)
  return features ? features.map(feat => ({
    ...feat,
    label: feat.place_name
  })) : []
}

const INTERESTS_LIMITE = 3;

const addOrRemoveItem = (arr, item, limit) => {
  return arr.includes(item) ? arr.filter(it => it !== item) : limit && arr.length >= limit ? [...arr.slice(0, -1), item] : [...arr, item]
}

export { renderSearchListItem, searchCollectionFormatter, addOrRemoveItem, INTERESTS_LIMITE }