import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import GlobalReset from './globalStyle/reset'
import GlobalFont from './globalStyle/font'

const rootEl = document.getElementById('root');
ReactDOM.render(
  <React.Fragment>
    <GlobalReset />
    <GlobalFont />
    <App />
  </React.Fragment>,
  rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
