import React, {useState, useCallback} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Modal from './components/Modal';
import Steps from './components/steps/Steps';
import Map from './components/map/Map';

import fakeZoneJson from './real.json';

import './App.css'

function App() {

  const [modalVisible, setModal] = useState(true)
  const closeModal = useCallback(() => {
    setModal(false)
  }, [])
  const openModal = useCallback(() => {
    setModal(true)
  }, [])

  // const [geoJson, setGeoJson] = useState(fakeZoneJson)
  const [geoJson, setGeoJson] = useState(undefined)

  return (
    <div className="App">
      <Header />
      <Map geoJson={geoJson} updateGeoJson={setGeoJson} openModal={openModal} />
      {!geoJson && <Footer />}
      <Modal>
        {modalVisible && <Steps setResult={setGeoJson} closeModal={closeModal} />}
      </Modal>
    </div>
  );
}

export default App;
