import React, { useState, Fragment } from "react";
import styled, {css} from 'styled-components';
import { Popup } from "react-mapbox-gl";
import IconLayer from "./layers/IconLayer";
import { getCoords, getProps } from "../../utils/mapHelper";
import formatLink from '../../utils/formatExternalLink';
import { MAP_TRAVEL_TIME_BG, PRIMARY_COLOR, DEFAULT_FONT_SIZE } from "../../constants/styleSetting";
import { StyledPopup, PopUpText } from "./styledComponents";

const LinkText = styled.a`
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  display: block;
`

const InfoText = styled.div`
  color: ${PRIMARY_COLOR};
`

const TitleText = styled.div`
  font-weight: 700;
  ${props => props.main && css`
    color: ${MAP_TRAVEL_TIME_BG};
    font-size: ${DEFAULT_FONT_SIZE};
  `}
`;

function ZonePoint({ feat, iconText, icon, size, popUpOffset, isMain }) {
  const [showInfo, setShowInfo] = useState(false);
  const featCoords = getCoords(feat);
  const featProps = getProps(feat);
  return featCoords ? (
    <Fragment>
      <IconLayer
        size={0.6}
        icon={icon}
        iconText={iconText}
        coordinates={featCoords}
        toggleInfo={bool => () => {
          setShowInfo(bool);
        }}
      />
      {featProps && (
        <Popup
          style={{ padding: "0px", margin: "0px", zIndex: showInfo ? '999': '3'}}
          offset={popUpOffset}
          coordinates={featCoords}
          onMouseEnter={(e) => {
            setShowInfo(true);
          }}
          onMouseLeave={() => {
            setShowInfo(false);
          }}
        >
          <StyledPopup main={isMain}>
            <PopUpText main={isMain}>
              <TitleText main={isMain}>{featProps.name || featProps.subcat}</TitleText>
              {showInfo && featProps.adresse}
              {showInfo && featProps.information && (
                featProps.cat === 'Stroll' ?
                  <InfoText>{featProps.information}</InfoText> :
                  <LinkText
                    href={formatLink(featProps.information)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    More info
                  </LinkText>
              )}
            </PopUpText>
          </StyledPopup>
        </Popup>
      )}
    </Fragment>
  ) : null;
}

export default ZonePoint;
