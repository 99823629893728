import React, { useState, useMemo, useCallback } from "react";
import isEmpty from 'lodash/isEmpty';
import { useFetch } from "../../customHooks/fetch";
import { getStepConfigs, STEP_COUNT } from "../../utils/getStepConfig";
import StepCounter from "./StepCounter";
import StepLoading from './StepLoading';
import StepIcon from './StepIcon';
import {getGeoJson} from "../../api/datacityApi";

import fakeZoneJson from "../../geo.json";

import {
  StepsContainer,
  StepSelectContainer,
  IconWrap,
} from "./styledComponents";

const fakeGeoFetch = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve({ data: fakeZoneJson }), 2000);
    // setTimeout(() => reject('nothing for you!'), 2000);
  });
}

function Steps({ closeModal, setResult }) {

  const [step, updateStep] = useState(0);

  const onNext = useCallback(() => {
    updateStep(step + 1);
  }, [step]);
  const onBack = useCallback(() => {
    updateStep(step - 1);
  }, [step]);

  // location step
  const [geoState, setGeoState] = useState({});

  // time window step
  const [timeWindow, setTimeWindow] = useState({startHour: '00', startMinute: '00', endHour:'00', endMinute: '00'})

  // interest step
  const [selectedInterests, updateInterest] = useState([])

  const restart = () => {
    updateStep(0);
    setGeoState({});
    setTimeWindow({startHour: '00', startMinute: '00', endHour:'00', endMinute: '00'});
    updateInterest([]);
    resetFetchedData(true);
  }

  // Api endpoint
  const [geoJsonState, lauchFetch, resetFetchedData] = useFetch(getGeoJson);

  const sendInfo = useCallback(() => {
    const { latitude, longitude } = geoState;
    const { startHour, endHour, startMinute, endMinute } = timeWindow;
    const start = `${startHour}:${startMinute}`;
    const end = `${endHour}:${endMinute}`;
    // await some ajax request info
    lauchFetch({latitude, longitude, start, end, selectedInterests})
  }, [geoState, timeWindow, lauchFetch, selectedInterests]);

  const hasResult = useMemo(() => !geoJsonState.isFetching && geoJsonState.result && !isEmpty(geoJsonState.result.data), [geoJsonState])
  if (hasResult) {
    setResult(geoJsonState.result.data);
    closeModal();
  }

  const { component: StepComponent, icon: Icon, prev, next, componentProps, validate } = useMemo(() => getStepConfigs({
    step,
    geoState,
    setGeoState,
    timeWindow,
    setTimeWindow,
    selectedInterests,
    updateInterest
  }), [step, geoState, setGeoState, timeWindow, setTimeWindow, selectedInterests, updateInterest]);

  return (
    <StepsContainer>
      {geoJsonState.isFetching || geoJsonState.hasError || (geoJsonState.result && isEmpty(geoJsonState.result.data)) ? (
        <StepLoading
          hasError={geoJsonState.hasError}
          isFetching={geoJsonState.isFetching}
          sendInfo={sendInfo}
          restart={restart}
        />
      ) : (
        <StepSelectContainer>
          <IconWrap>
            <StepIcon icon={Icon} />
          </IconWrap>
          <StepComponent {...componentProps} />
          <StepCounter
            total={STEP_COUNT}
            currentStep={step}
            prev={prev}
            next={next}
            onBack={onBack}
            onNext={onNext}
            sendInfo={sendInfo}
            disabled={!validate}
          />
        </StepSelectContainer>
      )}
    </StepsContainer>
  );
}

export default Steps;
