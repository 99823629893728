import styled, { css } from "styled-components";
import media from './MediaQueries';

import {
  PRIMARY_COLOR,
  DEFAULT_COLOR,
  NAV_BUTTON_COLOR,
  BUTTON_FONT_SIZE,
  DEFAULT_FONT_SIZE,
  SMALL_FONT_SIZE,
  DISABLED_COLOR,
  GREY_OUT_COLOR,
  BUTTON_UNSELECTED_BACKGROUND
} from "../../constants/styleSetting";

const Button = styled.button`
  font-size: ${BUTTON_FONT_SIZE};
  cursor: pointer;
  background: transparent;
  border-radius: 4px;
  border: 1px solid ${PRIMARY_COLOR};
  color: ${PRIMARY_COLOR};
  padding: 8px 16px;

  :focus {
    outline:0;
  }

  :hover {
    border: 1px solid ${PRIMARY_COLOR};
  }

  ${props =>
    props.unSelected &&
    css`
      border: 1px solid ${GREY_OUT_COLOR};
      color: ${DEFAULT_COLOR};
      background-color: ${BUTTON_UNSELECTED_BACKGROUND};
    `}
  
  ${props =>
    props.selected &&
    css`
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.24);
    `}

  ${props =>
    props.primary &&
    css`
      background: ${PRIMARY_COLOR};
      color: white;
    `}

  ${props =>
    props.white &&
    css`
      background: #fff;
    `}

  ${props =>
    props.round &&
    css`
      border-radius: 16px;
    `}

  ${props =>
    props.navigation &&
    css`
      font-size: ${DEFAULT_FONT_SIZE};
      background-color: ${NAV_BUTTON_COLOR};
      border: 1px solid ${NAV_BUTTON_COLOR};
      text-transform: uppercase;
      margin: 0.5em 1em;
      padding: 1em;
    `}

  ${media.desktop`
    font-size: ${SMALL_FONT_SIZE};
  `}

  ${props =>
    props.greyOut &&
    css`
      color: ${DISABLED_COLOR};
      border-color: ${GREY_OUT_COLOR};
      background-color: transparent;
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      border-color: ${GREY_OUT_COLOR};
      background-color: ${GREY_OUT_COLOR};
      :hover {
        border-color: ${GREY_OUT_COLOR};
      }
    `}

  ${props =>
    props.icon &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
`;

export default Button;
