import React, { useState, useEffect, useMemo, useCallback } from "react";
import isNil from "lodash/isNil";
import isEmpty from 'lodash/isEmpty';
import { MapContext } from './mapContext';
import MapView from "./MapView";
import MapPanel from "./MapPanel";
import { ReactComponent as RestartIcon } from "../../img/restart.svg";
import Button from "../common/Button";
import { prop } from '../../utils/lens';
import { getCoords, calcBounds, getPois } from "../../utils/mapHelper";
import { PARIS_BOUNDARIES } from "../../constants/mapSetting";

// get the initial bounds
const calcInitBounds = geoJson => {
  const boundsData = !isEmpty(geoJson)
    ? geoJson.reduce((acc, geo) => [...acc, ...getCoords(geo.zone)[0]], [])
    : null;
  return boundsData ? calcBounds([boundsData]) : PARIS_BOUNDARIES;
};

function Map({ geoJson, updateGeoJson, openModal }) {
  const [mapInstance, setMapInstance] = useState(undefined);

  const [selectedZone, updateSelectedZone] = useState(undefined);

  const noZoneSelected = useMemo(() => {
    return selectedZone === undefined;
  }, [selectedZone]);

  // calculate panel filter data from gesJson file & selectedZone
  const singleZonePoiFeatures = useMemo(() => {
    return !isEmpty(geoJson) && !isNil(selectedZone)
      ? getPois(prop(["poi", "features"], geoJson[selectedZone]))
      : undefined;
  }, [selectedZone, geoJson]);

  // put initBounds in memo
  const initBounds = useMemo(() => calcInitBounds(geoJson), [geoJson]);

  // update once when initBounds changed
  useEffect(() => {
    // reset to initBounds when panel closed (via noZoneSelected calculated by selectedZone)
    if (mapInstance && noZoneSelected) {
      mapInstance.fitBounds(initBounds);
    }
  }, [mapInstance, initBounds, noZoneSelected]);

  const [filtered, changeFilters] = useState([]);

  const onRestartClick = useCallback(() => {
    changeFilters([]);
    openModal();
    updateGeoJson(undefined);
    updateSelectedZone(undefined);
  }, [openModal, updateGeoJson]);

  return (
    <MapContext.Provider value={{noZoneSelected, filtered, singleZonePoiFeatures, updateSelectedZone}}>
      <content style={{ position: "relative", width: "100vw", display: "flex" }}>
        {!isEmpty(geoJson) && (
          <Button
            white
            icon
            onClick={onRestartClick}
            style={{
              position: "absolute",
              zIndex: 20,
              top: "93px",
              left: "20px"
            }}
          >
            <RestartIcon />
            <span style={{ marginLeft: "5px" }}>Restart experience</span>
          </Button>
        )}
        <MapView
          geoJson={geoJson}
          setMapInstance={setMapInstance}
          selectedZone={selectedZone}
          updateSelectedZone={updateSelectedZone}
        />
        {!noZoneSelected && (
          <MapPanel
            singleZonePoiFeatures={singleZonePoiFeatures}
            filtered={filtered}
            changeFilters={changeFilters}
            updateSelectedZone={updateSelectedZone}
          />
        )}
      </content>
    </MapContext.Provider>
  );
}

export default React.memo(Map);
