import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../constants/styleSetting';

const Checkmark = styled.div`
  display:inline-block;
  background-color: ${PRIMARY_COLOR};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  
  &:after{
    /*Add another block-level blank space*/
    content: '';
    display: block;
 
    /*Make it a small rectangle so the border will create an L-shape*/
    width: 3px;
    height: 6px;
 
    /*Add a white border on the bottom and left, creating that 'L' */
    border: solid #fff;
    border-width: 0 2px 2px 0;
 
    /*Rotate the L 45 degrees to turn it into a checkmark*/
    transform: translate3d(5px, 3px, 0) rotate(45deg);
  }
`

export default Checkmark;
