import axios from 'axios';

const apiPrefix = process.env.REACT_APP_API_ENDPOINT || ""

const getInterestsList = () => {
  return axios.get(`${apiPrefix}/cat/`)
}

const getGeoJson = (info) => {
  return axios.post(`${apiPrefix}/predict/`, info)
}

export { getInterestsList, getGeoJson }