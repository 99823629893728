import React from 'react'
import { Layer, Feature } from 'react-mapbox-gl'
import { PRIMARY_COLOR } from '../../../constants/styleSetting';

const getLinePaint = (color) => ({
  "line-color": color || PRIMARY_COLOR,
  "line-width": 4,
});

const PathLayer = ({ linePaint, color, coordinates }) => {
  const paint = linePaint || getLinePaint(color)
  return (
    !!coordinates && 
      <Layer
        type='line'
        paint={paint}
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
      >
        <Feature coordinates={coordinates} />
      </Layer>
  )
}

export default PathLayer
