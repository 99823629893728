import React, {useMemo, useCallback} from 'react'
import { Layer, Feature } from 'react-mapbox-gl';
import { PRIMARY_COLOR } from '../../../constants/styleSetting';
import { calcBounds } from '../../../utils/mapHelper';

const getPolygonPaint = (color, zoneSelected) => ({
  'fill-color': color || PRIMARY_COLOR,
  'fill-opacity': zoneSelected ? 0.3 : 0.5
});

const PolygonLayer = ({ color, coordinates, onPolygonClick, zoneSelected }) => {
  const paint = useMemo(() => getPolygonPaint(color, zoneSelected), [color,zoneSelected])
  const bounds = useMemo(() => calcBounds(coordinates), [coordinates])
  const onClick = useCallback(({target}) => {
    target.fitBounds(bounds)
    // target.setMinZoom(zoom);
    onPolygonClick();
  }, [onPolygonClick, bounds])
  const onMouseEnter = useCallback(({target}) => {
    target.getCanvas().style.cursor = 'pointer';
  }, [])
  const onMouseLeave = useCallback(({target}) => {
    target.getCanvas().style.cursor = '';
  }, [])
  if (!coordinates) {
    return null
  }
  return (
    coordinates.length > 1 ?
      coordinates.map(coord => {
        return <Layer
          key={`${coord[0]}-${coord[1]}`}
          type='fill'
          paint={paint}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Feature coordinates={[coord]} />
        </Layer>
      }) : 
      <Layer
        type='fill'
        paint={paint}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Feature coordinates={coordinates} />
      </Layer>
  )
}

export default PolygonLayer
