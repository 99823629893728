import React, { useMemo, useContext, Fragment } from "react";
import { MapContext } from "../map/mapContext";
import ZonePoint from "./ZonePoint";
import PathLayer from "./layers/PathLayer";
import IconLayer from "./layers/IconLayer";
import {
  formatJourneyData,
  getCoords,
  filterPois
} from "../../utils/mapHelper";
import { MAP_TRAVEL_TIME_BG } from "../../constants/styleSetting";

function MapDetails({ journey }) {
  const { filtered, singleZonePoiFeatures } = useContext(MapContext);
  const pois = useMemo(
    () => filterPois(singleZonePoiFeatures, filtered),
    [filtered, singleZonePoiFeatures]
  );
  const journeyData = useMemo(() => formatJourneyData(journey), [journey]);
  return (
    <>
      {pois &&
        pois.map(feat => {
          const coords = getCoords(feat);
          return (
            coords && (
              <ZonePoint
                key={`${coords[0]}-${coords[1]}`}
                feat={feat}
                popUpOffset={[0, -30]}
                icon="poiBlack"
                size={0.5}
              />
            )
          );
        })}
      {journeyData.paths &&
        journeyData.paths.map(feat => {
          const coords = getCoords(feat);
          return coords ? (
            <PathLayer
              key={`${coords[0]}-${coords[1]}`}
              linePaint={{
                "line-color": MAP_TRAVEL_TIME_BG,
                "line-width": 3,
                "line-dasharray": coords.map(coord => 2)
              }}
              coordinates={coords}
            />
          ) : null;
        })}
      {journeyData.points &&
        journeyData.points.map((feat, index) => {
          const coords = getCoords(feat);
          return (
            coords && (
              <Fragment key={`${coords[0]}-${coords[1]}`}>
                <IconLayer
                  icon="startPoint"
                  anchor="center"
                  size={0.3}
                  coordinates={getCoords(feat)}
                />
                <ZonePoint
                  feat={feat}
                  iconText={`${index + 1}`}
                  popUpOffset={[0, -60]}
                  icon="point"
                  size={0.6}
                  isMain
                />
              </Fragment>
            )
          );
        })}
    </>
  );
}

export default React.memo(MapDetails);
