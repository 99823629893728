import styled, {css} from "styled-components";
import { DISABLED_COLOR, SMALL_FONT_SIZE } from "../../constants/styleSetting";

const StyledPopup = styled.div`
  background: white;
  max-width: 200px;
  border-radius: 15px;
  margin: 0;
  padding: 0;
  ${props => props.main && css`
    border-radius: 8px;
  `}
`;

const PopUpText = styled.div`
  text-align: center;
  padding: 5px 10px;
  border-radius: 15px;
  width: 100%;
  font-size: ${SMALL_FONT_SIZE};
  color: ${DISABLED_COLOR};
  cursor: pointer;
  ${props => props.main && css`
    border-radius: 8px;
  `}
`;


export {StyledPopup, PopUpText}