import React from "react";
import styled from "styled-components";
// import turf from '@turf/helpers';
// import midpoint from '@turf/midpoint';
// import nearestPointOnLine from '@turf/nearest-point-on-line'
import { Popup } from "react-mapbox-gl";
import { ReactComponent as Subway } from "../../img/subway.svg";
import { MAP_TRAVEL_TIME_BG, MEDIUM_FONT_SIZE } from "../../constants/styleSetting";
import {getCoords,getProps} from "../../utils/mapHelper";
import IconLayer from "./layers/IconLayer";
import PathLayer from "./layers/PathLayer";

const StyledTravleTime = styled.div`
  background-color: ${MAP_TRAVEL_TIME_BG};
  border: 1px solid white;
  border-radius: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
`;

const TravleTimePopUpText = styled.div`
  font-size: ${MEDIUM_FONT_SIZE};
  padding: 5px;
`;

const ZoneMainPath = ({path, zoneCenter}) => {
  const coords = getCoords(path);
  const props = getProps(path);

  if (!coords) {
    return null;
  }
  // the center point of a given zone, equals to coords[0]
  const endPoint = zoneCenter;

  // middle point of the path coordinates data
  // const line = turf.lineString(coords);
  // const centerPoint = midpoint(turf.point(coords[0]), turf.point(coords[coords.length - 1]));
  // const middlePoint = getCoords(centerPoint);
  // console.log({coords, centerCoords})
  // const middlePoint = getCoords(nearestPointOnLine(line, pt));
  const middlePoint = coords[Math.round(coords.length / 2)];

  return (
    <>
      <PathLayer
        coordinates={coords}
        linePaint={{
          "line-color": MAP_TRAVEL_TIME_BG,
          "line-width": 4,
          "line-dasharray": [2, 2]
        }}
      />
      {props && (
        <Popup
          className="without-tip"
          offset={[0, 0]}
          coordinates={middlePoint}
        >
          <StyledTravleTime>
            <Subway style={{ width: "20px", height: "20px" }} />
            <TravleTimePopUpText>
              <span style={{ fontWeight: 700 }}>{`${Math.round(
                props["Time to arrive"]
              )}`}</span>{" "}
              mins
            </TravleTimePopUpText>
          </StyledTravleTime>
        </Popup>
      )}
      <IconLayer
        icon="arrive"
        anchor="center"
        size={0.4}
        coordinates={endPoint}
      />
    </>
  );
};

export default React.memo(ZoneMainPath);