import React, { useEffect } from "react";
import styled, {css} from 'styled-components';
import {GREY_OUT_COLOR, PRIMARY_COLOR} from '../../constants/styleSetting';
import { placeSearch } from '../../api/mapboxApi';
import { ReactComponent as LocationPin } from "../../img/location.svg";
import SearchBar from "./SearchBar";
import { StepWrap } from "./styledComponents";
import Loading from '../common/Loading';
import Checkmark from '../common/Checkmark';
import Button from "../common/Button";
import Flex from "../common/Flex";
import { useFetch } from "../../customHooks/fetch";
import getLocation from "../../api/geoloactionApi";
import { H1, AdditionalInfo, ErrorText } from "../common/Text";
import { renderSearchListItem, searchCollectionFormatter } from '../../utils/stepsHelper';

const fakeLoading = false

const GeoButton = styled(Button)`
  border-color: ${GREY_OUT_COLOR};
  ${props => props.hasResult && css`
    border-color: ${PRIMARY_COLOR};
  `}
`;

const renderButtonIcon = (navGeoState, hasResult) => {
  if (hasResult && !navGeoState.isFetching) {
    return <Checkmark />
  } else if (navGeoState.isFetching || fakeLoading) {
    return <Loading size="2px" style={{margin: '7px'}}/>
  }
  return <LocationPin />
}

function Location({ geoState, setGeoState }) {
  const [navigatorGeoState, getGeo] = useFetch(getLocation);

  const getNavigatorGeo = e => {
    getGeo(true);
  };

  const {result, hasError} = navigatorGeoState;

  useEffect(() => {
    if (result) {
      const { latitude, longitude } = result;
      setGeoState({latitude, longitude})
    }
  }, [result, setGeoState])

  const updateGeoByMapbox = selectedArr => {
    const geo = selectedArr[0].geometry.coordinates;
    setGeoState({
      latitude: geo[1],
      longitude: geo[0],
      selectedPlace: selectedArr[0].place_name,
    });
  };

  const hasResult = !!geoState.latitude && !geoState.selectedPlace

  return (
    <StepWrap noMaxHeight>
      <H1>Looking for an authentic travel experience in Paris?</H1>
      <AdditionalInfo>
        Tell us where you want to start your journey
      </AdditionalInfo>
      <Flex>
        <GeoButton hasResult={hasResult} round icon onClick={getNavigatorGeo}>
          {renderButtonIcon(navigatorGeoState, hasResult)}
          <span style={{ marginLeft: "5px" }}>Use current location</span>
        </GeoButton>
        <div style={{margin: '0 20px'}}>or</div>
        <SearchBar
          maybeItemName={geoState.selectedPlace}
          onSelectItem={updateGeoByMapbox}
          renderListItem={renderSearchListItem}
          collectionFormatter={searchCollectionFormatter}
          searchFunc={placeSearch}
        />
      </Flex>
      {hasError && <ErrorText>You have denied location service in the past, please re-enable</ErrorText>}
    </StepWrap>
  );
}

export default React.memo(Location);
