const getLocation = () => {
  const geolocation = navigator.geolocation;
  
  return new Promise((resolve, reject) => {
    if (!geolocation) {
      reject(new Error('Not Supported'));
    }
    
    geolocation.getCurrentPosition((position) => {
      resolve(position.coords);
    }, () => {
      reject (new Error('Permission denied'));
    });
  });
};

export default getLocation;