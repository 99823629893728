import styled, { css } from 'styled-components';
import media from './MediaQueries';

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  ${media.phone`
    flex-direction: column;
  `}

  ${props => props.vertical && css`
    flex-direction: column;
  `}

  ${props => props.shouldWrap && css`
    flex-wrap: wrap;
  `}

  ${props => props.alignLeft && css`
    justify-content: flex-start;
  `}
`;

export default Flex

