import React from 'react';
import styled from 'styled-components';
import { Heading } from './common/Text';
import { ReactComponent as Logo } from '../img/logo.svg';
import { HEADER_VERTICAL_PADDING } from '../constants/styleSetting';

const HeaderContainer = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  width: 100%;
  padding: ${HEADER_VERTICAL_PADDING}  36px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

function Header() {
  return (
    <HeaderContainer>
      <Logo style={{width: '92px', height: '33px'}}/>
      <Heading>Personalized itineraries off the beaten track</Heading>
    </HeaderContainer>
  );
}

export default Header;
