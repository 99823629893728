import styled, {css} from 'styled-components';
import { PRIMARY_COLOR, GREY_OUT_COLOR } from '../../constants/styleSetting';

const SelectCheck = styled.div`
  border: 1px solid ${GREY_OUT_COLOR};
  width: 16px;
  height: 16px;
  position: relative;
  margin: 5px;
  
  &:after{
    /*Add another block-level blank space*/
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
  }

  ${props => props.checked && css`
    border-color: ${PRIMARY_COLOR};
    &:after{
      background-color: ${PRIMARY_COLOR};
    }
  `}
`

export default SelectCheck;
