import styled from 'styled-components';
import { DISABLED_COLOR, ERROR_TEXT_COLOR } from '../../constants/styleSetting';

const Heading = styled.h1`
  font-size: 18px;
  font-style: italic;
  line-height: 1;
  margin-left: 15px;
`

const FooterText = styled.div`
  font-size: 12px;
  font-style: italic;
  line-height: 1;
  margin: 0 10px;
`

const ErrorText = styled.div`
  font-size: 12px;
  font-style: italic;
  line-height: 1;
  color: ${ERROR_TEXT_COLOR};
  margin: 10px;
`

const H1 = styled.h1`
  font-size: 30px;
`;

const H2 = styled.h2`
  font-size: 20px;
  font-weight: ${props => props.bold ? 700 : 'normal'};
  text-transform: uppercase;
`;

const AdditionalInfo = styled.h3`
  font-weight: 300;
  font-size: 16px;
  padding: 20px;
`;

const Details = styled.h4`
  font-size: 14px;
`;

const SubText = styled.div`
  font-size: 12px;
  color: ${DISABLED_COLOR};
`

export { H1, H2, AdditionalInfo, Details, SubText, Heading, FooterText, ErrorText }

