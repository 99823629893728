import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LoadingStep } from '../../img/calculating.svg';
import StepIcon from './StepIcon';
import Button from '../common/Button';
import Loading from '../common/Loading';
import { AdditionalInfo, ErrorText } from '../common/Text';

const LoadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 100px 0 rgba(0,0,0,0.24);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`

const NavButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

function StepLoading({isFetching, hasError, sendInfo, restart}) {
  if (isFetching) {
    return (
      <LoadingWrap>
        <StepIcon icon={LoadingStep} />
        <AdditionalInfo>
          Putting our best efforts into curating a travel guide just for you
        </AdditionalInfo>
        <Loading style={{margin: '30px auto'}}/>
      </LoadingWrap>
    )
  }
  if (hasError) {
    return (
      <LoadingWrap>
        <StepIcon icon={LoadingStep} />
        <ErrorText>
          Something went wrong, mind trying again ?
        </ErrorText>
        <NavButtonContainer>
          <Button navigation greyOut onClick={restart}>Restart</Button>
          <Button navigation primary onClick={sendInfo}>Try again</Button>
        </NavButtonContainer>
      </LoadingWrap>
    )
  }
  return (
    <LoadingWrap>
      <StepIcon icon={LoadingStep} />
      <AdditionalInfo>
        We cannot find trips for your, maybe try other combinations ?
      </AdditionalInfo>
      <Button navigation primary onClick={restart}>Restart experience</Button>
    </LoadingWrap>
  )
}

export default StepLoading