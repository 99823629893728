import React, {useEffect} from "react";
import styled from "styled-components";
import { StepWrap } from "./styledComponents";
import Button from "../common/Button";
import media from '../common/MediaQueries';
import { H1, AdditionalInfo, ErrorText } from "../common/Text";
import { useFetch } from "../../customHooks/fetch";
import { getInterestsList } from "../../api/datacityApi";
import { addOrRemoveItem, INTERESTS_LIMITE } from '../../utils/stepsHelper';
import { PRIMARY_COLOR, DEFAULT_COLOR } from '../../constants/styleSetting';
import categoryMap from '../../constants/categoryMap';

import fakeBlocks from '../../filters.json';


const InterestsBlock = styled.div`
  padding: 5px 10px;
  text-align: left;
  width: 100%;
`;

const InterestHeader = styled.h3`
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
`;

const SelectedBadge = styled.span`
  position: absolute;
  right: -10px;
  top: -10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${PRIMARY_COLOR};
  background-color: ${PRIMARY_COLOR};
  color: white;
`

const InterestsButton = styled(Button)`
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  margin: 10px 20px 10px 0;
  padding: 16px;
  width: calc(25% - 20px);
  span {
    display: block;
    flex: 0 0 calc(100% - 35px)
  }
  ${media.tablet`
    width: calc(50% - 20px);
  `}
`;

function Interests({ selectedInterests, updateInterest }) {
  const [blocks, getBlocks] = useFetch(getInterestsList)
  // launch the search when component mount
  useEffect(() => {
    getBlocks(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onInterestButtonClick = (subCat) => (e) => {
    const newSelected = addOrRemoveItem(selectedInterests, subCat, INTERESTS_LIMITE);
    updateInterest(newSelected)
  }
  const data = blocks.result ? blocks.result.data : null;
  if (!data) {
    return null
  }
  return (
    <StepWrap wide>
      <H1>Tell us about your interests... </H1>
      <AdditionalInfo>
        Pick your interests from all items in order of preference. We will do
        our best to curate a guide just for you.
      </AdditionalInfo>
      {data.map(({ categorie, sub_categorie }) => (
        <InterestsBlock key={categorie}>
          <InterestHeader>{categorie}</InterestHeader>
          <div>
            {sub_categorie.map((subCat) => {
              const { label, Icon } = categoryMap[subCat]
              const maybeItemIndex = selectedInterests.findIndex(item => item === label);
              const isSelected = maybeItemIndex > -1;
              return (
                <InterestsButton
                  key={subCat}
                  unSelected={!isSelected}
                  selected={isSelected}
                  icon
                  onClick={onInterestButtonClick(subCat)}
                  style={{color: PRIMARY_COLOR}}
                >
                  <Icon
                    style={{ width: "30px", height: "30px", marginRight: "5px", flex: "0 0 30px" }}
                  />
                  <span style={{color: isSelected ? PRIMARY_COLOR : DEFAULT_COLOR}}>{label}</span>
                  {isSelected && <SelectedBadge>{maybeItemIndex + 1}</SelectedBadge>}
                </InterestsButton>
              )
            })}
          </div>
        </InterestsBlock>
      ))}
    </StepWrap>
  );
}

export default Interests;
