import { prop } from "./lens";
import orderBy from "lodash/orderBy";

const formatJourneyData = journey =>
  journey.features
    ? journey.features.reduce((acc, feat) => {
        if (feat.geometry.type === "Point") {
          return {
            ...acc,
            points: acc.points ? [...acc.points, feat] : [feat]
          };
        } else if (feat.geometry.type === "LineString") {
          return {
            ...acc,
            paths: acc.paths ? [...acc.paths, feat] : [feat]
          };
        }
        return acc;
      }, {})
    : null;

// obj is in geojson format
const getCoords = obj => prop(["geometry", "coordinates"], obj);
const getProps = obj => prop(["properties"], obj);

const getPois = features => {
  if (!features.length) {
    return []
  }
  const itemToTakeEachSubCategory = 5;
  const groupedBySubCat = features.reduce((acc, feat) => {
    const subcat = prop(["properties", "subcat"], feat);
    return {
      ...acc,
      [subcat]: acc[subcat] ? [...acc[subcat], feat] : [feat]
    };
  }, {});
  return Object.values(groupedBySubCat).reduce(
    (acc, subGroup) => [...acc, ...orderBy(subGroup, ["properties.importance", "desc"]).slice(0, itemToTakeEachSubCategory - 1)],
    []
  );
};

const filterPois = (features, filtered) => {
  if (!features.length) {
    return null
  }
  return features.filter(feat => !filtered.includes(getProps(feat).subcat));
};

const calcBounds = polygonCoords =>
  polygonCoords[0].reduce(
    (acc, [lng, lat]) => {
      const xMin = Math.min(acc[0][0] || lng, lng);
      const yMin = Math.min(acc[0][1] || lat, lat);
      const xMax = Math.max(acc[1][0] || lng, lng);
      const yMax = Math.max(acc[1][1] || lat, lat);
      return [[xMin, yMin], [xMax, yMax]];
    },
    [[], []]
  );

export {
  formatJourneyData,
  getCoords,
  getProps,
  calcBounds,
  getPois,
  filterPois,
};
