/*--------------colors----------------*/
export const PRIMARY_COLOR = '#00ACE5';
export const STEP_ICON_COLOR = '#00ACE5';
export const DISABLED_COLOR = '#909090';
export const GREY_OUT_COLOR = '#D1D1D1';
export const NAV_BUTTON_COLOR = '#00ACE5';
export const BUTTON_UNSELECTED_BACKGROUND = '#FBFBFB';
export const ERROR_TEXT_COLOR = '#CE0B24';


/*--------------fonts----------------*/
export const DEFAULT_FONT_SIZE = '16px';
export const MEDIUM_FONT_SIZE = '14px';
export const SMALL_FONT_SIZE = '12px';
export const DEFAULT_LINE_HEIGHT = 1.5;
export const DEFAULT_COLOR = '#1D1D1D';

export const BUTTON_FONT_SIZE = '14px';

/*--------------spaces----------------*/
export const HEADER_VERTICAL_PADDING = '20px';


/*--------------general icon sizes----------------*/
export const STEP_ICON_SIZE = 112


/*--------------map colors----------------*/
export const MAP_TRAVEL_TIME_BG = '#0C61A9';
// polygon shape & legend colors
export const MAP_LAYERS_COLOR = ['#915DC0', '#1CADE2', '#29B493'];