import React, {useCallback} from 'react'
import { Layer, Feature } from 'react-mapbox-gl'

const defaultIconSize = {
  base: 0.1,
  stops: [
    [0, 0.1],
    [5, 0.2],
    [8, 0.3],
    [10, 0.4],
    [11, 0.5],
    [12, 0.6],
    [13, 0.7],
    [14, 0.8],
    [15, 0.9],
    [16, 1.0],
    [17, 1.0],
    [18, 1.0],
    [19, 1.0],
    [20, 1.0],
  ],
}

const IconLayer = ({ icon, iconText, size, anchor, coordinates, toggleInfo }) => {
  const onIconClick = useCallback((e) => {
    e.target.flyTo({center: coordinates})
    e.originalEvent.stopPropagation();
  }, [coordinates])
  return (coordinates &&
    <Layer
      type='symbol'
      layout={{
        'icon-image': icon || 'poi',
        'icon-size': size || defaultIconSize,
        'icon-anchor': anchor || 'bottom',
        'icon-allow-overlap': true,
        'symbol-avoid-edges': true,
        'text-allow-overlap': true,
        "text-field": iconText || "",
        'text-anchor': 'bottom',
        'text-offset': [0,-1.7],
      }}
      onMouseMove={toggleInfo && toggleInfo(true)}
      onMouseLeave={toggleInfo && toggleInfo(false)}
      onClick={onIconClick}
    >
      <Feature coordinates={coordinates} />
    </Layer>)
}

export default IconLayer
