import { createGlobalStyle } from 'styled-components';
import { DEFAULT_FONT_SIZE, DEFAULT_LINE_HEIGHT, DEFAULT_COLOR } from '../constants/styleSetting';

const GlobalFont = createGlobalStyle`
@font-face {
  font-family: "Roboto";
  font-style: normal;
	src: url("/fonts/robotocondensed-regular-webfont.woff2") format("woff2"),
          url("/fonts/robotocondensed-regular-webfont.woff") format("woff"),
          url("/fonts/robotocondensed-regular-webfont.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
	src: url("/fonts/robotocondensed-light-webfont.woff2") format("woff2"),
          url("/fonts/robotocondensed-light-webfont.woff") format("woff"),
          url("/fonts/robotocondensed-light-webfont.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
	src: url("/fonts/robotocondensed-italic-webfont.woff2") format("woff2"),
          url("/fonts/robotocondensed-italic-webfont.woff") format("woff"),
          url("/fonts/robotocondensed-italic-webfont.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
	src: url("/fonts/robotocondensed-bold-webfont.woff2") format("woff2"),
          url("/fonts/robotocondensed-bold-webfont.woff") format("woff"),
          url("/fonts/robotocondensed-bold-webfont.ttf") format("ttf");
}

body,html {
  line-height: ${DEFAULT_LINE_HEIGHT};
  font-family: "Roboto", Avenir, Helvetica, arial, sans-serif;
  font-style: normal;
  font-size: ${DEFAULT_FONT_SIZE};
  color: ${DEFAULT_COLOR};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`
export default GlobalFont