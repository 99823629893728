import axios from 'axios';
import { PARIS_CENTER, ACCESS_TOKEN, LARGE_PARIS_BOUNDARIES } from '../constants/mapSetting';

const access_token = process.env.REACT_APP_MAPBOX_TOKEN || ACCESS_TOKEN;

const placeSearch = (value) => {
  if (value !== '') {
    return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json`, {
      params: {
        access_token: access_token,
        autocomplete: 'true',
        country: 'FR',
        proximity: PARIS_CENTER.join(','),
        bbox: [...LARGE_PARIS_BOUNDARIES[0], ...LARGE_PARIS_BOUNDARIES[1]]
      }
    })
  }
  return {
    data: []
  }
}

export { placeSearch }